export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.login.title.main': 'Вход в каталог',
  'pages.login.accountLogin.tab': 'Вход в аккаунт',
  'pages.login.accountLogin.errorMessage': 'Неправильное имя пользователя/пароль',
  'pages.login.username.placeholder': 'Логин',
  'pages.login.username.required': 'Пожалуйста введите логин!',
  'pages.login.password.placeholder': 'Пароль',
  'pages.login.password.required': 'Пожалуйста введите пароль!',
  'pages.login.phoneLogin.tab': 'Вход через телефон',
  'pages.login.phoneLogin.errorMessage': 'Ошибка кода верификации',
  'pages.login.phoneNumber.placeholder': 'Номер телефона',
  'pages.login.phoneNumber.required': 'Пожалуйста введите номер телефона!',
  'pages.login.phoneNumber.invalid': 'Неверный номер телефона!',
  'pages.login.captcha.placeholder': 'Код верификации',
  'pages.login.captcha.required': 'Пожалуйста введите код верификации!',
  'pages.login.phoneLogin.getVerificationCode': 'Получить код',
  'pages.getCaptchaSecondText': 'секунд(а)',
  'pages.login.rememberMe': 'Запомнить меня',
  'pages.login.forgotPassword': 'Забыли пароль ?',
  'pages.login.submit': 'Логин',
  'pages.login.loginWith': 'Логин через :',
  'pages.login.registerAccount': 'Регистрация аккаунта',
  'pages.welcome.advancedComponent': 'Расширенный компонент',
  'pages.welcome.link': 'Добро пожаловать',
  'pages.welcome.advancedLayout': 'Расширенный шаблон',
  'pages.admin.subPage.title': 'Эта страница доступна только для аминистратора',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
  'pages.searchTable.createForm.newRule': 'Новый столбец',
  'pages.searchTable.productDescription.title': 'Карточка товара',
  'pages.searchTable.productDescription.descriptionText': 'Описание',
  'pages.searchTable.productDescription.titleText': 'Наименование', 
  'pages.searchTable.productDescription.photoText': 'Фото',
  'pages.searchTable.productDescription.featuresText': 'Характеристики', 
  'pages.searchTable.updateForm.ruleConfig': 'Конфигурация столбца',
  'pages.searchTable.updateForm.basicConfig': 'Базовая информация',
  'pages.searchTable.updateForm.ruleName.productLabel': 'Наименование',
  'pages.searchTable.updateForm.ruleName.productImage': 'Картинка',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Пожалуйста введите наименование!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Описание столбца',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Не менее 5 символов',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Пожалуйста введите не менее 5 символов!',
  'pages.searchTable.updateForm.ruleProps.title': 'Задать настройки',
  'pages.searchTable.updateForm.object': 'Мониторинг обьекта',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Rule Template',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Rule Type',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Установить период планирования',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Начальное время',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Пожалуйста выберите начальное время!',
  'pages.searchTable.titleCat': 'Категория',
  'pages.searchTable.ruleName': 'Rule name is required',
  'pages.searchTable.titleProductClass': 'Класс',
  'pages.searchTable.titleSubCat': 'Подкатегория',
  'pages.searchTable.titleProductCount': 'Кол-во',
  'pages.searchTable.nameStatus.default': 'по умолчанию',
  'pages.searchTable.nameStatus.running': 'запущено',
  'pages.searchTable.nameStatus.online': 'онлайн',
  'pages.searchTable.nameStatus.abnormal': 'ненормально',
  'pages.searchTable.titleUpdatedAt': 'Запланирован на',
  'pages.searchTable.exception': 'Пожалуйста введите причину исключения!',
  'pages.searchTable.titleOption': 'Опция',
  'pages.searchTable.config': 'Настройки',
  'pages.searchTable.subscribeAlert': 'Подписаться на уведомления',
  'pages.searchTable.title': 'Таблица товаров',
  'pages.searchTable.new': 'Добавить',
  'pages.searchTable.chosen': 'выбран',
  'pages.searchTable.item': 'продукт',
  'pages.searchTable.titleBrand': 'Брэнд',
  'pages.searchTable.totalServiceCalls': 'Общее количество вызовов',
  'pages.searchTable.batchDeletion': 'Удалить выбранное',
  'pages.searchTable.batchApproval': 'Подтвердить выбранное',
};
