import React from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import { notification } from 'antd';
import { history } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import type { RequestConfig, RunTimeLayoutConfig } from 'umi';
import type { ResponseError } from 'umi-request';
// import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
// import { BookOutlined, LinkOutlined } from '@ant-design/icons';
import {reactLocalStorage} from 'reactjs-localstorage';

// const isDev = process.env.NODE_ENV === 'development';

/** When obtaining user information is slow, one will be displayed loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  userToken?: string;
  // fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  // const fetchUserInfo = async (token: string) => {
  //   try {
  //     const fetchedToken = {"Authorization": token};
  //     const currentUser = await queryCurrentUser(fetchedToken);
  //     console.log(currentUser);
  //     return currentUser;
  //   } catch (error) {
  //     history.push('/user/login');
  //   }
  //   return undefined;
  // };

  // If it is a login page, do not execute
  if (history.location.pathname !== '/user/login') {
    // const {userToken} = initialState; 
    // const currentUser = await fetchUserInfo();
    return {
      // fetchUserInfo,
      // currentUser,
      // userToken,
      settings: {},
    };
  }
  return {
    // fetchUserInfo,
    settings: {},
  };
}

// https://umijs.org/zh-CN/plugins/plugin-layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  const user = reactLocalStorage.get('current-user');
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // If you are not logged in, redirect to login
      if (!user && location.pathname !== '/user/login') {
        history.push('/user/login');
      }
    },
    links: 
    // isDev
    //   ? [
    //       <>
    //         <LinkOutlined />
    //         <span
    //           onClick={() => {
    //             window.open('/umi/plugin/openapi');
    //           }}
    //         >
    //           openAPI Documentation
    //         </span>
    //       </>,
    //       <>
    //         <BookOutlined />
    //         <span
    //           onClick={() => {
    //             window.open('/~docs');
    //           }}
    //         >
    //           Business component documentation
    //         </span>
    //       </>,
    //     ]
      // :
       [],
    menuHeaderRender: undefined,
    // customize 403 page
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};

const codeMessage = {
  200: 'The server successfully returned the requested data.',
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'The data was deleted successfully.',
  400: 'There was an error in the request sent, and the server did not create or modify data.',
  401: 'The user does not have permission (the token, username, password is wrong).',
  403: 'The user is authorized, but access is forbidden.',
  404: 'The request sent was for a record that did not exist, and the server did not operate.',
  405: 'The request method is not allowed.',
  406: 'The requested format is not available.',
  410: 'The requested resource is permanently deleted and will no longer be available.',
  422: 'When creating an object, a validation error occurred.',
  500: 'An error occurred on the server, please check the server.',
  502: 'Gateway error.',
  503: 'The service is unavailable, and the server is temporarily overloaded or maintained.',
  504: 'The gateway timed out.',
};

/** Exception handler
 * @see https://beta-pro.ant.design/docs/request-cn
 */
const errorHandler = (error: ResponseError) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;

    notification.error({
      message: `Request error ${status}: ${url}`,
      description: errorText,
    });
  }

  if (!response) {
    notification.error({
      description: 'Your network is abnormal and you cannot connect to the server',
      message: 'network anomaly',
    });
  }
  throw error;
};

// https://umijs.org/zh-CN/plugins/plugin-request
export const request: RequestConfig = {
  errorHandler,
};
