import React from 'react';
import { DefaultFooter } from '@ant-design/pro-layout';

export default () => (
  <DefaultFooter
    copyright="2021 AVTECH"
    links={[
      {
        key: 'avtech',
        title: 'avtech',
        href: 'https://avtech.uz',
        blankTarget: true,
      },
    ]}
  />
);
