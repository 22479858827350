export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'Больше блоков',
  'menu.home': 'Домашняя',
  'menu.admin': 'Админ',
  'menu.admin.sub-page': 'Подменю',
  'menu.login': 'Логин',
  'menu.register': 'Регистрация',
  'menu.register.result': 'Результат регистрации',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Аналитика',
  'menu.dashboard.monitor': 'Мониторинг',
  'menu.dashboard.workplace': 'Рабочяя площадка',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Форма',
  'menu.form.basic-form': 'Базовая форма',
  'menu.form.step-form': 'Пошаговая форма',
  'menu.form.step-form.info': 'Пошаговая форма(опишите информацию о переводе)',
  'menu.form.step-form.confirm': 'Пошаговая форма(подтвердите информацию о переводе)',
  'menu.form.step-form.result': 'Пошаговая форма(завершено)',
  'menu.form.advanced-form': 'Расширенная форма',
  'menu.list': 'Список',
  'menu.list.table-list': 'Поиск по продукции',
  'menu.list.basic-list': 'Базовый список',
  'menu.list.card-list': 'Список карточек',
  'menu.list.search-list': 'Поиск по списку',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Профиль',
  'menu.profile.basic': 'Базовый профиль',
  'menu.profile.advanced': 'Расширенный профиль',
  'menu.result': 'Результат',
  'menu.result.success': 'Успешно',
  'menu.result.fail': 'Не удалось',
  'menu.exception': 'Исключение',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Источник',
  'menu.account': 'Аккаунт',
  'menu.account.center': 'Аккаунт основа',
  'menu.account.settings': 'Настройки аккаунта',
  'menu.account.trigger': 'Источник ошибки',
  'menu.account.logout': 'Выйти',
  'menu.editor': 'Графический редактор',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
