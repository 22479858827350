export default {
  'component.globalHeader.search': 'Поиск',
  'component.globalHeader.search.example1': 'Search example 1',
  'component.globalHeader.search.example2': 'Search example 2',
  'component.globalHeader.search.example3': 'Search example 3',
  'component.globalHeader.help': 'Помощь',
  'component.globalHeader.notification': 'Уведомление',
  'component.globalHeader.notification.empty': 'Вы просмотрели все уведомления.',
  'component.globalHeader.message': 'Сообщение',
  'component.globalHeader.message.empty': 'Вы просмотрели все сообщения.',
  'component.globalHeader.event': 'События',
  'component.globalHeader.event.empty': 'Вы просмотрели все события.',
  'component.noticeIcon.clear': 'Очистить',
  'component.noticeIcon.cleared': 'Очищено',
  'component.noticeIcon.empty': 'Нет уведомлений',
  'component.noticeIcon.view-more': 'Посмотреть еще',
};
